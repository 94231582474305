import type { ReactNode } from "react";
import styles from "./Green.module.scss";

export type GreenProps = ReactNode;

function Green(children: GreenProps) {
  return <span className={styles.g}>{children}</span>;
}

export default Green;

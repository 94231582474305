import { faBars } from "@fortawesome/pro-solid-svg-icons/faBars";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, MenuItem } from "@szhsin/react-menu";
import clsx from "clsx";
import { useTranslations } from "use-intl";
import Co2CodeModal from "../modal/Co2CodeModal";
import { NAV_ITEMS } from "./nav-links";
import NavLink from "./NavLink";
import styles from "./ProfileMenu.module.scss";

export type NavMenuProps = {};

const Dashboard = {
  key: "dashboard",
  path: "/dashboard",
  langKey: "startPage" as keyof IntlMessages["components"]["Layout"]["nav"],
  disabled: false,
};

function NavMenu({}: NavMenuProps) {
  const t = useTranslations("components.Layout");

  return (
    <Menu
      align="center"
      direction="bottom"
      transition
      menuButton={
        <FontAwesomeIcon
          className={styles.buttonIcon}
          size="xl"
          icon={faBars}
          style={{ cursor: "pointer" }}
        />
      }
    >
      <MenuItem disabled={Dashboard.disabled}>
        <NavLink navItem={Dashboard}>{t(`nav.${Dashboard.langKey}`)}</NavLink>
      </MenuItem>
      <MenuItem disabled={NAV_ITEMS.code.disabled}>
        <Co2CodeModal
          openElement={({ onOpen }) => (
            <div
              onClick={onOpen}
              className={clsx(
                styles.link,
                styles.linkContainer,
                NAV_ITEMS.code.disabled && styles.linkDisabled
              )}
            >
              {t(`nav.${NAV_ITEMS.code.langKey}`)}
            </div>
          )}
        />
      </MenuItem>
      {/*<MenuItem disabled={NAV_ITEMS.shop.disabled}>*/}
      {/*  <NavLink navItem={NAV_ITEMS.shop}>*/}
      {/*    {t(`nav.${NAV_ITEMS.shop.langKey}`)}*/}
      {/*  </NavLink>*/}
      {/*</MenuItem>*/}
      <MenuItem disabled={NAV_ITEMS.abo.disabled}>
        <NavLink navItem={NAV_ITEMS.abo}>
          {t(`nav.${NAV_ITEMS.abo.langKey}`)}
        </NavLink>
      </MenuItem>
      <MenuItem disabled={NAV_ITEMS.calc.disabled}>
        <NavLink navItem={NAV_ITEMS.calc}>
          {t(`nav.${NAV_ITEMS.calc.langKey}`)}
        </NavLink>
      </MenuItem>
    </Menu>
  );
}

export default NavMenu;

import clsx from "clsx";
import { useRouter } from "next/router";
import { PropsWithChildren } from "react";
import pkg from "../../../package.json";
import { LayoutConfig } from "../../../types/util";
import Header from "../Header/Header";
import styles from "./Layout.module.scss";

export type LayoutProps = PropsWithChildren<{
  a?: boolean;
  config?: LayoutConfig;
}>;

function Layout({ a, children, config }: LayoutProps) {
  const router = useRouter();
  return (
    <div
      className={clsx(
        styles.container,
        router.pathname === "/public/[publicId]" && styles.pub
      )}
    >
      {!config?.hideHeader && (
        <Header
          ignoreSession={config?.ignoreSession}
          hideNavigation={config?.hideNavigation}
        />
      )}
      <main
        className={clsx(
          styles.main,
          a && styles.admin,
          router.pathname === "/public/[publicId]" && styles.pub
        )}
      >
        {children}
      </main>
      <footer className={styles.footer}>
        ©&nbsp;{new Date().getFullYear()} Green Aureus GmbH | Alle Rechte
        vorbehalten
        {(process.env.NODE_ENV !== "production" || a) && (
          <span className={styles.version}>v{pkg.version}</span>
        )}
      </footer>
    </div>
  );
}

Layout.Messages = ["components.Layout", ...Header.Messages];

export default Layout;

import { signOut } from "next-auth/react";
import { useCallback } from "react";
import { LogoutAction } from "../redux/actions";
import { ResetApi } from "../redux/apiSlice";
import { useAppDispatch } from "../redux/hooks";

function useLogout() {
  const dispatch = useAppDispatch();

  // noinspection UnnecessaryLocalVariableJS
  const logout = useCallback(async () => {
    await signOut({ redirect: true, callbackUrl: "/auth/login" });
    dispatch(LogoutAction());
    dispatch(ResetApi());
  }, [dispatch]);

  return logout;
}

export default useLogout;

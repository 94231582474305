import Link, { LinkProps } from "next/link";
import { PropsWithChildren } from "react";
import useIsActiveLink from "../../hooks/useIsActiveLink";
import { NAV_ITEMS } from "./nav-links";

export type NavLinkProps = {
  navItem: (typeof NAV_ITEMS)[keyof typeof NAV_ITEMS];
} & Omit<LinkProps, "href" | "data-active">;

function NavLink({ children, navItem }: PropsWithChildren<NavLinkProps>) {
  const isActive = useIsActiveLink();

  return (
    <Link
      data-active={isActive(navItem.path)}
      href={navItem.disabled ? {} : navItem.path}
    >
      {children}
    </Link>
  );
}

export default NavLink;
